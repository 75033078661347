import React from "react"
import classNames from "classnames";
import _ from "lodash"
import Slider from "react-slick"
import {CategoryMember} from "../ProductCategory"
import ProductPrice from "../ProductPrice"
import ProductTag from "../ProductTag"
import ProductTitle from "../ProductTitle"
import {ImgProduct, ImgUnset} from "../Image"
import AddToCart from "../AddToCart"
import "slick-carousel/slick/slick.css"
import "../../styles/slick-theme.css"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default ({item, cartProducts, productGroup, loading, location, successCallback}) => (
  <div className={classNames({loading: loading})}>
    {!_.isEmpty(item) ? (
      <>
        <div className={classNames("container", "bg-white", "inner", "product-detail")}>
          <div className="unit">
            {_.isEmpty(item.ecImage) ? (
              <ImgUnset/>
            ) : (
              <Slider {...settings}>
                {_.map(item.ecImage, (image, key) => (
                  <div key={key}>
                    <ImgProduct item={item} index={key}/>
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="unit">
            <CategoryMember memberList={_.get(item, "ecCategory.member")}/>
            <ProductTag tagList={{sellingStatus: _.get(item, "ecSellingStatus")}}/>
            <ProductTitle item={item} group={productGroup}/>
            <ProductPrice price={_.get(item, "NetPrice")} style={{
              fontSize: "22px",
            }}/>
            <div className="product-add-cart">
              {cartProducts.map(product => (
                product && <AddToCart key={_.get(product, "Hin")} item={product} productGroup={productGroup} location={location} successCallback={successCallback}/>
              ))}
            </div>
          </div>
        </div>
        <div className="container bg-white inner" style={{paddingTop: 0}}>
          <div className="editor" dangerouslySetInnerHTML={{__html: _.get(item, "ecBody")}}/>
        </div>
      </>
    ) : (
      item == null && (
        <div className={classNames("container", "bg-white", "inner")} style={{marginTop: "40px"}}>
          <p style={{textAlign: "center"}}>商品はございません。</p>
        </div>
      )
    )}
  </div>
)
